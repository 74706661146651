<template>
  <div>
    <v-toolbar tile flat elevation="1">
      <v-btn :to="{name: 'CabinetSeries'}" class="no-active" icon>
        <v-icon>$vuetify.icons.arrow_left</v-icon>
      </v-btn>
      <v-toolbar-title v-if="serieId">Редактировать цикл</v-toolbar-title>
      <v-toolbar-title v-else>Добавить цикл</v-toolbar-title>
    </v-toolbar>
    <v-container v-if="!loadPage" class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12" md="8">
          <v-card class="elevation-0">
            <v-form v-model="valid" ref="form">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      v-model="title"
                      name="inputTitle"
                      label="Заголовок"
                      required
                      :rules="titleRules"
                    ></v-text-field>

                    <v-textarea
                      name="inputBody"
                      label="Описание"
                      value
                      v-model="body"
                      :rules="bodyRules"
                    ></v-textarea>

                    <v-checkbox v-model="completed" label="Цикл завершен"></v-checkbox>

                    <v-btn v-if="serieId" dark color="primary" @click="updateSerie">Обновить</v-btn>
                    <v-btn v-else dark color="primary" @click="submit">Сохранить</v-btn>
                  </v-col>
                </v-row>
            </v-form>
          </v-card>
        </v-col>
      </v-row>


    </v-container>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data: () => ({
    serieId: null, //Id серии которую будем редактировать
    valid: true,
    loading: false,
    title: "",
    body: "",
    completed: 0,
    requiredRules: [v => !!v || "Поле обязательно"],
    titleRules: [
      v => !!v || "Поле обязательно",
      v => v.length >= 3 || "Минимум 3 символа"
    ],
    bodyRules: [
      // v => !!v || 'Поле обязательно',
      v => !!v === v.length >= 10 || "Минимум 10 символов"
    ]
  }),
  methods: {
    validate() {
      return this.$refs.form.validate();
    },
    submit() {
      if (!this.validate()) return;
      this.loading = true;
      let app = this;
      this.$store
        .dispatch("cabinet_series/addSerie", {
          title: app.title,
          body: app.body,
          completed: app.completed,
        }).then((response) => {
          app.$router.push({
              name: "CabinetSerieEdit",
              params: { id: response.data.serie.id }
            });
        });
    },
    updateSerie() {
      if (!this.validate()) return;
      let app = this;
      this.$store
        .dispatch("cabinet_series/updateSerie", {
          serie_id: app.serieId,
          title: app.title,
          body: app.body,
          completed: app.completed,
        });
    },
    getSerie() {
      let app = this;
      this.$store
        .dispatch("cabinet_series/getSerie", {
          serie_id: app.serieId,
        });
    },
    clear() {
      this.title = "";
      this.body = "";
      this.completed = 0;
    },
    updateData(){
      // console.log('update_data')
      this.title = this.serie.title;
      this.body = this.serie.body;
      this.completed = +this.serie.completed;
    }
  },
  watch: {
    serie() {
      if(this.serie){
        this.updateData();
      }
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "currentUser",
      loadPage: "loadPage",
      serie: "cabinet_series/serie"
    })
  },
  mounted() {
    this.serieId = this.$route.params.id;
    if(this.serieId){
        this.getSerie();
    }
    //Если имеется id - редактирование, нету - добавление
  }
};
</script>
